import { NextPage } from 'next'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import Head from 'next/head'

export const Error500: React.FC = () => {

  return (
		<div>
			<Head>
			  <meta charSet="UTF-8" />
			  <meta name="viewport" content="width=device-width" />
			  <title>500 Internal Server Error | 株式会社ベツダイ</title>
			  <meta name="description" />
			  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
			  <meta name="format-detection" content="telephone=no" />
			  <meta property="og:type" content="website" />
			  <meta property="og:title" content="404 Not found | 株式会社ベツダイ" />
			  <meta property="og:site_name" content="404 Not found | 株式会社ベツダイ" />
			  <meta property="og:description" />
			  <meta property="og:url" content="https://betsudai.co.jp" />
			  <meta property="og:image" content="https://betsudai.co.jp/img/ogp.jpg" />
			  <meta name="twitter:card" content="summary_large_image" />
			  <meta name="twitter:url" content="https://betsudai.co.jp" />
			  <meta name="twitter:title" content="404 Not found | 株式会社ベツダイ" />
			  <meta name="twitter:description" />
			  <meta name="twitter:image" content="https://betsudai.co.jp/img/ogp.jpg" />
			  <link rel="shortcut icon" href="/favicon.png" />
			  <link rel="apple-touch-icon" href="/img/apple-touch-icon.png" />
			</Head>
		  <noscript>
		    &lt;style&gt;body{'{'}visibility:visible{'}'}&lt;/style&gt;
		  </noscript>
		  <div className="c-page-sub__base">
		    <div className="c-page-sub__header">
		    	<Header />
		    </div>
		    <div className="c-page-sub__breadcrumb">
		      <div className="c-nav-breadcrumb">
		        <ol>
		          <li className="c-nav-breadcrumb__item"><a href="/">トップ</a></li>
		          <li className="c-nav-breadcrumb__item" data-breadcrumb="current">500 Internal Server Error</li>
		        </ol>
		      </div>
		    </div>
		    <div className="c-page-sub__main">
		      <main>
		        <article>
		          <div className="c-page-sub__title-page">
		            <div className="c-title-page" data-page="default">
		              <h1><span data-lang="en"></span>500<span data-lang="ja">Internal Server Error</span></h1>
		            </div>
		          </div>
		          <div className="c-page-sub__content-main">
		            <div className="c-content-main">
		              <section data-type="section">
		                <p className="center">サーバーエラーが発生しました。</p>
		                <p className="center">サーバーの問題でお探しのページを表示できません。<br />再度時間をおいてアクセスしてください。</p>
		                <div data-item="button">
		                  <p data-button-kind="back"><a data-type="btn" href="/"><span>トップページへ戻る</span></a></p>
		                </div>
		              </section>
		            </div>
		          </div>
		        </article>
		      </main>
		    </div>
		    <div className="c-page-sub__footer">
					<Footer />
		    </div>
		  </div>
		</div>	
  )
}
