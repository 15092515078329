import { NextPage } from 'next'
import { ErrorProps } from 'next/error'
import { GetServerSideProps } from 'next'
import { Error404 } from '../components/404'
import { Error500 } from '../components/500'

const Error: NextPage<ErrorProps> = ({ statusCode }) => {

  return (
  	<>
  		{statusCode == 404 ? (
				<Error404 />		
  		) : (
  			<Error500 />
  		)}
		</>
  )
}

Error.getInitialProps = async ({ res, req }) => {
	const statusCode = res ? res.statusCode : 404;

	// TODO: エラーログ

	return { statusCode }
}

export default Error
